
.bg-logo{
  background-color: #065885;
  padding: 20px 20px;
}

.bg-logo img{
  width: 200px;

}

.wrapper{
    box-shadow: 0 0 5px 0 rgba(125,121,121,.4);
    padding: 45px 30px;
    border-radius: 5px;
   
}



.aadhar-input input {
  width: 30%;
  padding: 10px 0px;
  border: 1px solid gray;
  border-radius: 5px;
  outline: none;
  text-align: center;
}

#adhar-otp .aadhar-input input {
  width: 8%;

}

.adhar-img {
  border: 2px solid #11589a;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  margin: 15px 0;
}

.aadhar-user-pic {
  border-radius: 50%;
  height: 100px;
  width: 100px !important;
  margin: 15px 0px;
 
}

.ap-line{
  padding-bottom: 10px;
}

.customer-phone select {
  display: inline-block;
  width: 15%;
  text-align: center;
}

.form-group-style select {
  padding: 15px 0px 15px 5px;
  margin-right: 16px;
  background: #FFFFFF;
  border: 1px solid #B5B5B5;
  border-radius: 6px;
  outline: none;
}

.form-group-style input {
  padding: 15px 12px;
  background: #FFFFFF;
  border: 1px solid #B5B5B5;
  border-radius: 6px;
  letter-spacing: 0.2em;
  outline: none;
}

.profile-box-m {
  width: 330px;
  box-shadow: 1px 4px 5px 0px rgba(21, 37, 72, 0.12);
  display: flex;
  padding: 20px;
  padding-bottom: 15px;
  margin: auto;
  text-align: left;
  margin-bottom: 30px;
  border-radius: 5px;
}

.profile-box-m img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.prop-nam {
  margin-left: 15px;
}

.f-tag p {
  background: #0055a5;
  color: #fff !important;
  width: 50%;
  border-radius: 50px;
  text-align: center;
  margin-top: 10px;
  font-size: 14px !important;
  font-weight: 500;
  padding: 3px;
}

.profile-box-n {
  width: 100%;
  margin: inherit;
  border: 1.5px solid #1565D8;
  border-radius: 10px;
  margin-top: 40px;
  box-shadow: 1px 4px 5px 0px rgba(21, 37, 72, 0.12);
  display: flex;
  padding: 20px;
  padding-bottom: 15px;

}

.profile-box-n img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
}

.aadhar-term input {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-top: 14px;
  cursor: pointer;
}

#createabha .form-group-style input {
  padding: 15px 12px;
  background: #FFFFFF;
  border: 1px solid #B5B5B5;
  border-radius: 6px;
  letter-spacing: 0em;
  outline: none;
}
.message-box{
  padding: 15px 12px;
  background: #848484;
  border-radius: 6px;
}
@media screen and (max-width: 768px){
  #adhar-otp .aadhar-input input {
    width: 20%;
  
  }

  .ap-line {
    font-size: 14px;
}

.aadhar-user-pic {
  height: 69px;
  width: 69px !important;
}

.adhar-img {
  padding: 15px 8px;
}

.wrapper {
  padding: 45px 15px;
}
}